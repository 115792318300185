import { type ReactNode } from 'react';
import React, { createContext } from 'react';

import { type Role, rolePermissions, type Permission } from './role';
// Define the shape of the permission context
export interface UserContextProps {
  roles: Role[]
  permissions: Permission[]
}
const UserContext = createContext<UserContextProps | undefined>(undefined);

const combinedPermissions = (roles: Role[]): Permission[] => {
  const combinedPermissions = new Set<Permission>();
  roles.forEach(role => {
    rolePermissions[role].forEach(permission => combinedPermissions.add(permission));
  });
  return Array.from(combinedPermissions);
}

export const UserProvider = ({ children, groups }: { children: ReactNode, groups: string[] }): JSX.Element => {
  const [roles] = React.useState<Role[]>(groups as Role[]);
  const permissions = combinedPermissions(roles);

  return (
    <UserContext.Provider value={{ roles, permissions }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = (): UserContextProps => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}
