import type React from 'react';
import {
  ApolloProvider as Provider,
  ApolloClient,
  HttpLink,
  InMemoryCache
} from '@apollo/client';

export function ApolloProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    cache,
    link: new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI
    })
  });

  return <Provider client={client}>{children}</Provider>;
}
