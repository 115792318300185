interface AuthConfig {
  clientId: string
  redirectUri: string
  userInfo: string
  scopes: string[]
}
interface BaseConfig {
  appFullName: string
  appName: string
  description: string
  isProd: boolean
  oAuth: AuthConfig
}

export const config: BaseConfig = {
  appFullName: 'Middle Mile TOP UI',
  appName: 'Middle Mile TOP UI',
  description: 'Middle Mile TOP UI',
  isProd: process.env.REACT_APP_NODE_ENV === 'production',
  oAuth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: window.location.origin,
    userInfo: process.env.REACT_APP_USER_INFO,
    scopes: [
      'profile openid email'
    ]
  }
};
