
const writeRole = process.env.REACT_APP_NODE_ENV === 'production' ? 'App.Transportation.OpsPortal.MiddleMile.prod.Write' : 'App.Transportation.OpsPortal.MiddleMile.test.Write';
const readRole = process.env.REACT_APP_NODE_ENV === 'production' ? 'App.Transportation.OpsPortal.MiddleMile.prod.ReadOnly' : 'App.Transportation.OpsPortal.MiddleMile.test.ReadOnly';
export enum Role {editor = 'Write', viewer = 'ReadOnly'}
(Role as any).editor = writeRole;
(Role as any).viewer = readRole;
export enum Permission {read = 'read', write = 'write', delete = 'delete'}
export const rolePermissions: Record<Role, Permission[]> = {
  [Role.editor]: [Permission.read, Permission.write, Permission.delete],
  [Role.viewer]: [Permission.read]
}
export interface User {
  role: Role
  permission: Permission[]
}
export const rolePriority = [Role.editor, Role.viewer];
