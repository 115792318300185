import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type NavSubItem, VerticalNavbar, setActiveValueInNavItems, type NavEvent } from '@nike/eds';
import routesUrl from '../../constants/routesUrl';

interface NavItem {
  id: string
  icon: string | React.ReactElement
  label: string
  active: boolean
  subItems?: NavSubItem[]
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CurrentUser {
  firstName: string
  lastName: string
  initials: string
  email: string
}

// eslint-disable-next-line react/no-unused-prop-types
function NavBar (props: { loggedInUser: any, handleLogout: any }): JSX.Element {
  const height = window.innerHeight;
  const navigate = useNavigate();
  // const [userDetails, setUserDetails] = useState<CurrentUser>();

  // useEffect(() => {
  //   const splitEmail = props.loggedInUser.split('.')
  //   const initials = ((splitEmail[0]).charAt(0) + (splitEmail[1]).charAt(0)).toUpperCase();
  //   const userDetails = {
  //     firstName: (splitEmail[0]),
  //     lastName: (splitEmail[1]).split('@')[0],
  //     initials,
  //     email: props.loggedInUser
  //   }
  //   setUserDetails(userDetails)
  // }, [])

  const [items, setItems] = useState<NavItem[] | undefined>([
    {
      id: routesUrl.dashboard,
      icon: 'Home',
      label: 'Dashboard',
      active: true
    },
    {
      id: routesUrl.routeGuide,
      icon: 'Shipping',
      label: 'Routing Guide',
      active: false
    },
    {
      id: routesUrl.carrierGuide,
      icon: 'Shipping',
      label: 'Carrier Management',
      active: false
    }
  ]);
  const [bottomItems, setBottomItems] = useState<NavItem[] | undefined>([
    {
      id: routesUrl.dashboard,
      icon: 'Settings',
      label: 'Settings',
      active: false
    }
  ]);

  const handleClick = (item: NavEvent): void => {
    const newItems = setActiveValueInNavItems(item.id, items);
    const newBottomItems = setActiveValueInNavItems(item.id, bottomItems);
    setItems(newItems);
    setBottomItems(newBottomItems);
    navigate(item.id);
  };

  return (
        <div style={{ position: 'relative', height }}>
            <VerticalNavbar
                panelLogoSlot={
                    <img
                        alt="Placeholder Logo"
                        src="https://unsplash.com/photos/R_0rTS9ENnk/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjQ0MjY4ODM1"
                        style={{ objectFit: 'cover' }}
                        height="48px"
                        width="48px"
                    />
                }
                appName="MM TOP"
                appSubtitle="Middle-Mile Transportation Ops Portal"
                onNavigate={handleClick}
                items={items ?? []}
                bottomItems={bottomItems}
            />
        </div>
  );
}

export default NavBar;
