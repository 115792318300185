export const globalHeaders: any = { 'Content-Type': 'application/json' };

export function setGlobalHeader (name: string, value: string | null): void {
  globalHeaders[name] = value;
}

function isValidHttpCode (code: number): boolean {
  if (code >= 200 && code < 400) {
    return true;
  }
  return false;
}
const http = {
  isValidHttpCode
};

export default http
