/* eslint-disable @typescript-eslint/no-unused-vars */
import './App.css';
import React, { useEffect, useState } from 'react';
import { config } from './config/config';
import { setGlobalHeader } from './utilities/http';
import { AegisClient, useAuth } from '@nike/aegis-auth-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Button } from '@nike/eds';
// import Header from './components/Header/Header';
import NavBar from './components/NavBar/NavBar';
import InternalRoutes from './route';
import { UserProvider } from './context/UserContext';
const client = new AegisClient({
  qa: !config.isProd,
  ...config.oAuth
});

function App (): JSX.Element {
  const { loginComplete, isLoggedIn, clearLogin } = useAuth(client);
  const [userToken, setUserToken] = useState<string | null>();
  const [loggedInUser, setLoggedInUser] = useState<string | null>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    function handleLogin ({ accessToken }: any): void {
      setGlobalHeader('Authorization', `Bearer ${accessToken.accessToken}`);
      setUserToken(accessToken.accessToken);
      setGroups([...accessToken.claims.groups]);
      setLoggedInUser(accessToken.claims.sub);
    }

    client.on('login:success', handleLogin);
    client.on('renew:access:success', handleLogin);
  }, []);

  if (!isLoggedIn) return <LoggedOut />;

  function handleLogout (params: any): void {
    if (params) {
      setGlobalHeader('Authorization', null);
      clearLogin();
      void client.logout();
      setUserToken(null);
      setGroups([]);
      setLoggedInUser(null);
    }
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        {loginComplete && userToken &&
        <div className="eds-flex" style={{ backgroundImage: 'url(./circle.jpg)' }}>
            <NavBar loggedInUser= {loggedInUser} handleLogout={handleLogout}/>
            <div className="eds-flex--grow-1" style={{ overflow: 'hidden' }}>
              <div className="eds-flex--grow-1 eds-spacing--p-20">
                <UserProvider groups={groups}>
                  <Routes>
                  {InternalRoutes.map((route) => (
                    <Route
                      key={route.name}
                      path={route.path}
                      element={<route.component groups={groups} />}
                    />
                  ))}
                  </Routes>
                </UserProvider>
              </div>
             </div>
          </div>
        }
      { (!loginComplete || !userToken) &&
         <div style={{ margin: '50px', textAlign: 'center' }}>
            <div style={{ marginBottom: '20px' }}>
              <span className="epic-body-text-1 table-label" />
                <p>
                  <span className="epic-body-text-1 table-label">
                     You do not have the necessary permissions to view this page. Please request access to App.Transportation.OpsPortal.MiddleMile.test.ReadOnly or App.Transportation.OpsPortal.MiddleMile.test.Write AD group if you require access
                  </span>
                </p>
            </div>
            <Button onClick={(event: any) => (window.location.href = '/')}>Login</Button>
        </div>
      }
    </BrowserRouter>
    </React.Fragment>
  );
}

function LoggedOut (): JSX.Element {
  return (
        <div style={{ margin: '50px', textAlign: 'center' }}>
            <div style={{ marginBottom: '20px' }}>
        <span className="epic-body-text-1 table-label">
          You have been logged out from application.
        </span>
                <p>
          <span className="epic-body-text-1 table-label">
            Close the browser to log out from Okta session.
          </span>
                </p>
            </div>
            <Button onClick={(event: any) => (window.location.href = '/')}>Login</Button>
        </div>
  );
}

export default App;
