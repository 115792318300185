import { Spinner } from '@nike/eds';
import routes from './constants/routesUrl';
import React, { Suspense } from 'react'; // Import React and Suspense

const Dashboard = React.lazy(async () => await import('./components/Dashboard/Dashboard'));
const CarrierGuide = React.lazy(async () => await import('./components/CarrierGuide/CarrierGuide'));
const RouteGuide = React.lazy(async () => await import('./components/RouteGuides/RouteGuide'));

// eslint-disable-next-line react/display-name
const getLazyComponent = (Component: React.ComponentType<any>) => (props: any) => (
        <Suspense fallback={<Spinner />}>
            <Component {...props} />
        </Suspense>
);
const InternalRoutes = [
  {
    name: 'Carrier Guide',
    path: routes.carrierGuide,
    exact: true,
    component: getLazyComponent(CarrierGuide)
  },
  {
    name: 'Shipping',
    path: routes.routeGuide,
    exact: true,
    component: getLazyComponent(RouteGuide)
  },
  {
    name: 'Dashboard',
    path: routes.dashboard,
    exact: true,
    component: getLazyComponent(Dashboard)
  }
];

export default InternalRoutes
